@import '../../assets/variable';
@import '../../assets/mixins';
@import '../../assets/global';


.Info{
  h1{color:$ored;margin-bottom:0px;}
  .title{padding-top:60px;text-align:center;
    .med3Def{max-width:800px;}
    p{margin-bottom:0;}
    p a{color:$orange;}
  }  
  .is1{padding:60px 0;}
  h4{font-weight:bold;text-transform:uppercase;margin-bottom:0;color:$color1;}
  hr.med{width:90%;max-width:1100px;@include center-block();margin-bottom:50px;}
  p b{font-weight:500;}
  p a{color:$color2;font-weight:500;word-wrap:break-word;}
  p.dir{color:$ored;width:92%;@include center-block();margin-bottom:2px;font-weight:600;text-transform:uppercase;line-height:1.3em;}

  li{border-bottom:1px solid rgba($black,.1);margin-bottom:15px;
    &:last-child{border-bottom:none;}
    &.groupEnd{margin-bottom:40px;}

    p{position:relative;z-index:0;padding-left:16px;
      &::before{@include before();width:10px;height:10px;margin-top:8px;background-color:$yellow;border-radius:50%;}
    }
  }
  
  @include bp(nn){
    .title{padding-top:30px;}
    h1{font-size:35px;letter-spacing:2px;}
    p.dir{margin-top:10px;}
    .is1{padding:30px 0;}
    .is1 .medDef{width:90%;}
    hr.med{margin-bottom:30px;}
    li{padding-right:10px;}
  }
  @include bp(tn){h1{letter-spacing:1px;}p.dir{max-width:500px;margin-top:15px;margin-bottom:5px;}}
  @include bp(oc){
    h1{font-size:29px;}
    h4{font-size:19px;}
    p{font-size:17px;}

    li.groupEnd{margin-bottom:30px;}
  }
  @media (max-width:300px){h1{font-size:25px;}}
}