@import '../../assets/variable';
@import '../../assets/mixins';
@import '../../assets/global';


.Airbnb{@include sec-default();padding:60px 0;text-align:center;
  .is1{position:relative;}
  .inside{width:95%;}
  .sliderItems{margin-left:0;justify-content:center;}
  .colLi{padding-bottom:80px;
    .imgWrap{overflow: hidden;}

    .sitem{width:96.5%;@include center-block();position:relative;z-index:0;
      &:hover{opacity:1;
        img{transform:scale(1.05);@include ease(.3s);}
        p,.arrow{opacity:.85;}
      }
    }
    .textbox{position:absolute;width:94%;left:3%;bottom:-10%;padding:7px 27px 7px 15px;background-color:$white;z-index:1;box-shadow:0px 10px 30px rgba($black,.25);}

    a{color:inherit;}
    p{line-height:1.3em;margin:1px 0;}//text-align:left;}
    p.abhd{font-size:17px;text-transform:uppercase;font-weight:500;}//font-weight:bold;}
    p.sm{font-size:15px;color:$color1;}
    // .arrow{position:absolute;right:-5px;top:24%;width:27px;height:27px;border-radius:50%;text-align:center;background-color:lighten($color1b,7%);box-shadow:0px 5px 15px rgba($black,.1);
    //   svg{width:14px;@include center-block();margin-top:8px;}
    // }
  }
  .slideNav{width:34px;height:34px;background-color:$color1b;color:$color2-dk;border-radius:50%;text-align:center;box-shadow:0px 5px 20px rgba($black,.15);
    margin:0;padding:0;z-index:99;visibility:visible !important;// display:block !important;
    svg{width:10px;@include center-block();}
  }

  @media (min-width:1200px){
    .slideContainer{overflow:visible !important;}
    .colLi{max-width:416px;
      .sitem:hover .arrow{background-color:$color1b;opacity:1;}

      p{text-align:left;}
      .arrow{position:absolute;right:-5px;top:24%;width:27px;height:27px;border-radius:50%;background-color:lighten($color1b,7%);box-shadow:0px 5px 15px rgba($black,.1);
        svg{width:14px;@include center-block();margin-top:8px;}
      }
      .linkText{display:none;}
    }
  }
  @media (min-width:1361px){.colLi{max-width:436px;}}
  //1300
  @media (max-width:1199px){padding-bottom:40px;
    .inside{max-width:840px;}

    .colLi{padding-bottom:105px;
      .textbox{bottom:-60px;padding-left:10px;padding-right:10px;}
      .arrow{font-weight:bold;color:$orange;
        .linkText{font-size:14px;}
        svg{width:12px;margin-left:5px;margin-top:1px;fill:$orange;}
      }
    }

    .slideNav{
      &.prev{left:-2%;}
      &.next{right:-2%;}
    }
  }
  @include bp(nn){padding:30px 0 0;
    h1{font-size:35px;}
  }
  // @include bp(tn){.colLi p br{display:none;}}
  @include bp(oc){padding-top:20px;
    h1{font-size:30px;}
    .inside{max-width:450px;}
  }
  @include bp(fi){
    .inside{width:90%;}
    h1{font-size:27px;}
    .slideNav{
      &.prev{left:-2.5%;}
      &.next{right:-2.5%;}
    }
  }


  .beforeSliderVersion{
    .inside2{width:95%;}
    .col{width:32%;max-width:420px;margin:10px .5%;box-shadow:0px 10px 30px rgba($black,.1);
      a{color:inherit;}
      p{line-height:1.3em;text-align:left;margin:1px 0;}
      p.abhd{font-size:17px;text-transform:uppercase;font-weight:bold;}
      p.sm{font-size:15px;color:$color1;}
      .arrow{position:absolute;right:-5px;top:24%;width:27px;height:27px;border-radius:50%;text-align:center;background-color:lighten($color1b,7%);box-shadow:0px 5px 15px rgba($black,.1);
        svg{width:14px;@include center-block();margin-top:8px;}
      }
      .imgWrap{overflow:hidden;}
      
      .sitem{@include sec-default();position:relative;z-index:0;
        &:hover{opacity:1;
          img{transform:scale(1.05);@include ease(.3s);}
          span{background-color:$color1b;}
          p{opacity:.85;}
        }
      }
      .textbox{padding:7px 10px 7px 15px;background-color:$white;z-index:1;}
    }
  }
}