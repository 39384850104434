// @import url("https://use.typekit.net/gxj1cpf.css");
@import './variable';
@import './mixins';

// @font-face {
//   font-family: 'Better Valentina Sans';
//   src: url('./fonts/BetterValentinaSans.ttf');
//   src: 
//     url('./fonts/BetterValentinaSans.otf') format('otf'),
//     url('./fonts/BetterValentinaSans.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
// <link rel="preload" href="BetterValentinaSans.woff2" as="font" type="font/woff2" crossorigin>
@font-face {
  font-family: 'Better Valentina Sans';
  src: url('./fonts/BetterValentinaSans.eot');
  src: url('./fonts/BetterValentinaSans.eot?#iefix') format('embedded-opentype'),
      url('./fonts/BetterValentinaSans.woff2') format('woff2'),
      url('./fonts/BetterValentinaSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.flexGrid{display:flex;align-items:center;justify-content:center;}
.gsbrWrap,.bgWrap,.fullSec{@include sec-default();}
.fullRow,.kpGrid{@include sec-default();
  // .flexWrap{display:flex;align-items:center;justify-content:space-around;}
  .hlf,.hlf1,.hlf2,.col,.left,.right{display:inline-block;vertical-align:top;}
  .hlf1,.col1,.left{float:left;}
  .hlf2,.col3,.right{float:right;}

  .hlf{width:48%;}
  .grid25{width:25%;}
  .grid30{width:30%;}
  .grid33{width:33%;}
  .grid35{width:35%;}
  .grid40{width:40%;}
  .grid45{width:45%;}
  .grid50{width:50%;}
  .grid55{width:55%;}
  .grid60{width:60%;}
  .grid65{width:65%;}
  .grid67{width:67%;}
  .grid25, .qtr{width:25%;}

  .size{width:auto;margin-left:20px;margin-right:20px;}

  .left.pull{margin-left:-3%;}
  .right.pull{margin-right:-3%;}
  .left.push{margin-left:3%;}
  .right.push{margin-right:3%;}
  .mid3{margin-left:5.5%;}

  // @include bp(br){
  //   .medDef.mediaStack{max-width:680px;}
  //   .medDef.mediaStack .left, .medDef.mediaStack .right{width:100% !important;}
  // }
}

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .med4Def, .insideXl, .insideN, .insideNrw, .insideTw, .custWrap{@include center-block();width:90%;}
.inside, .insideAlt, .insideXl, .insideN{max-width:1600px;}
.insideXl{width:95%;}
.inside{width:85%;}
.insideAlt{width:80%;}
.insideTw{max-width:1200px;}
.medDef{max-width:1000px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}
@include bp(tb){.medDef, .med2Def, .med3Def, .med4Def, .insideNrw, .insideTw, .custWrap{width:92%;}}

.clearfix::after{content:"";clear:both;display:table;}
.textCenter{text-align:center;}

p,h5,h4,h3,h2,h1,span{
  &.bold{font-weight:700;}
  &.semi{font-weight:500;}
  &.bmargin0{margin-bottom:0;}
  &.bmargin5{margin-bottom:5px;}
  &.tmargin0{margin-top:0;}
}
h1.style{font-size:47px;font-family:$fun;letter-spacing:3px;color:$orange;line-height:1.1em;
  @include bp(oc){letter-spacing:1px;}
}


.linkRow{@include sec-default();}
.gsLink, .gsLinkLg{background-color:$ored;color:$white;text-align:center;line-height:1em;text-transform:uppercase;letter-spacing:1px; font-weight:600;
  &:hover{text-decoration:none;@include ease(.3s);opacity:.85;color:$white;}

  &.white{background-color:$white;color:$ored;}
  &.org{background-color:$orange;}
  &.shadow{box-shadow:0px 10px 30px rgba($black,.15);}
}
.gsLink{font-size:15px;padding:7px 20px;}
.gsLinkLg{font-size:16px;padding:10px 30px;}
.ilLink{display:inline-block;margin-left:10px;margin-right:10px;}
// .button,.gsLink{position:relative;z-index:0;}
// .button:before, .gsLink::before{@include before();left:unset;right:0;width:0;transition: 0.6s ease-in-out;}
// .button:hover:before, .gsLink:hover:before {right:auto;left:0;width:100%;}

.hoverLink{position:relative;z-index:0;
  &::before, &::after{@include after();left:0;bottom:2px;height:4px;background:currentColor;}//border-bottom:3px solid currentColor;}
  &::before{opacity:.3;}
  &::after{width:0;opacity:.8;}
  &:hover::after{width:100%;transition:.3s all;}
}

.dotNav{
  a{border-color:$color2-dk;}
  a:hover{background-color:rgba($color2,.3);}
}