@import '../../assets/variable';
@import '../../assets/mixins';
@import '../../assets/global';


/*******************************/
/*********** FOOTER ***********/
/*******************************/
.footWrap{background-color:$color1Lt;position:relative;z-index:0;
  &::before,&::after{@include after();left:0;bottom:0;}
  &::before{height:30px;background-color:$color1b;}
  &::after{height:110px;background-image: url("../../assets/images/shotWave2.png");background-size:100%;background-position:top left;background-repeat:no-repeat;}


  .inside{width:90%;}
  .topFt{margin:50px 0 70px;position:relative;z-index:0;
    &::before{@include before();width:33.3%;border-right:2px solid rgba($color2, .2);}

    .colMain{width:33%;}
    .contact{padding-left:30px;}

    .ftLogo{width:100%;max-width:300px;@include center-block();}
    li{font-size:15px;display:inline-block;}
    a{color:$color2;}
    .menu{float:left;margin-top:20px;width:36%;
      li{margin:3px 20px 3px 0;}
    }
    .soc{margin-top:-2px;margin-left:10px;}
    .genMen{
      li a{font-weight:500;}
      li:nth-child(2){margin-left:15px;margin-right:15px;}
    }
    h5{font-weight:bold;text-transform:uppercase;font-size:22px;letter-spacing:1px;margin-bottom:0px;}
    p{font-size:16px;margin:3px 0;}
    .drawerRow{display:none;}
  }

  @media (min-width:1700px){
    &::after{height:120px;}
    .topFt{margin-bottom:90px;}
  }
  @media (min-width:2000px){
    &::after{height:140px;}
    .topFt{margin-bottom:115px;}
  }
  @media (min-width:2500px){
    &::after{height:160px;}
    .topFt{margin-bottom:125px;}
  }
  @media (min-width:1301px){
    .inside{max-width:1200px;}
    .topFt{padding:10px 0;
      &::before{left:33.3%;border-left:2px solid rgba($color2, .2);}
      .menu{margin-left:-2%;text-align:right;}
      .menu ul{@include sec-default();margin-left:-6%;}
    }
  }

  @media (max-width:1300px){
    &::after{height:90px;}
    .inside{max-width:800px;}
    .topFt{
      &::before{width:50%;height:150px;}
      .logoCol,.contact{width:50%;}
      .logoCol{padding-right:30px;}
      .menu{width:100%;text-align:center;margin-top:30px;
        ul{max-width:740px;@include center-block();}
      }
      .ftLogo{float:right;}
      .contact{margin-top:10px;}
    }
  }
  @include bp(nn){
    &::after{height:75px;}
    .topFt{margin:30px 0 50px; h5{font-size:19px;}}
  }
  @include bp(og){
    .topFt{margin:15px 0 40px;
      &::before{display:none;}
      .logoCol,.contact{@include sec-default();text-align:center;padding:0;}
      .ftLogo{max-width:250px;float:none;}

      .contact{margin-top:0;}
      .genMen{display:none;}
      .drawerRow{@include sec-default();margin:10px 0;
        button{text-transform:uppercase;background-color:$color2;box-shadow:0px 10px 20px rgba($black,.1);padding:6px 12px;
          color:$white;letter-spacing:1px;
          span{margin-right:10px;}
        }
      }
      .menu{display:none;}
      h5{font-size:17px;margin-bottom:0;}
    }
  }
  @include bp(oc){&::after{height:80px;}&::before{height:50px;}}

  @include bp(xs){
    .topFt{margin-top:5px;
      h5{font-size:16px;}
      p{font-size:15px;}
      .emailp{word-wrap:break-word;line-height:1.3em;}
    }
  }

  
  .footCopy{@include sec-default();text-align:center;line-height:1.3em;//position:relative;z-index:0;
    p{font-size:14px;display:inline-block;padding:3px 2px 0;margin:12px 0 10px;color:$color2;}
    a{font-size:inherit;color:darken($color1,10%);;}

    @media (max-width:1300px){
      p{margin:0px 0 10px;}
    }
    @include bp(oc){padding-bottom:4px;
      p{@include sec-default();margin-bottom:5px;line-height:1em;}
    }
  }
}