@import '../../assets/variable';
@import '../../assets/mixins';
@import '../../assets/global';


.Groceries{
  .gsIntro{//padding:100px 0;max-height:310px; 
    @include sec-bgimage('./images/grocery.jpg');background-size:cover;text-align:center;
    // h1,p{text-shadow:0px 1px 2px rgba($black,.1);}
    // h1{font-size:55px;color:$color2;letter-spacing:2px;line-height:1.1em;font-family:$fun;margin-bottom:0;}
    // p{font-weight:500;@include center-block();}

    padding:60px 0;//background-position:bottom center;
    position:relative;z-index:0;&::before{@include before();background-color:$yover;opacity:.3;}
    h1{font-size:55px;color:$color2;letter-spacing:2px;line-height:1.1em;font-family:$fun;margin-bottom:0;text-shadow:0px 1px 2px rgba($black,.2);}
    p{font-weight:500;margin-bottom:30px;}//text-shadow:0px 1px 2px rgba($black,.4);}
    // h1,p{color:$white}

    a{display:inline-block;margin:5px 10px;padding:10px 25px;background-color:$orange;color:$white;box-shadow:0px 10px 20px rgba($black,.2);
      font-size:16px;line-height:1.5em;
      span{font-size:18px;font-weight:bold;text-transform:uppercase;letter-spacing:2px;}
    }
  }

  @include bp(tw){.inside1{width:90%;}}
  @include bp(nn){
    .inside1{width:95%;}
    .gsIntro{padding:40px 0;
      &::before{opacity:.5;}
      h1{font-size:45px;letter-spacing:1px;}
      .med3Def{max-width:650px;}
      p{font-size:17px;margin-bottom:20px;}
      a{font-size:15px;
        span{font-size:17px;}
      }
    }
  }
  @include bp(og){
    .gsIntro{padding:30px 0 25px;//position:relative;z-index:0;max-height:unset;
      // &::before{@include before();background-color:rgba($black,.5);}
      &::before{background-color:$black;opacity:.5;}
      h1,p{color:$white;text-shadow:0px 1px 2px rgba($black,.4);}
      h1{font-size:30px;margin-bottom:3px;}
      .med3Def{max-width:600px;}
      p, a span{font-size:16px;}//margin-bottom:0;width:95%;max-width:400px;}
      a{background-color:$ored;font-size:14px;padding:10px 20px;margin-left:5px;margin-right:5px;
        span{letter-spacing:1px;}
      }
    }
  }
  @include bp(fi){
    .gsIntro{padding:20px 0;
      h1{font-family:$body;font-size:22px;letter-spacing:0;text-transform:uppercase;}
      a:first-child{margin-bottom:12px;margin-top:0;}
    }
  }

  .gs1{padding:60px 0 50px;
    .med3Def{max-width:900px;}
    
    .r1{margin-bottom:50px;
      .left{margin-top:4%;padding-right:50px;}
      .right{padding-left:15px;}
    }
    .r2 .left{margin-left:-4%;margin-top:10px;}
    h3{font-size:32px;font-weight:bold;text-transform:uppercase;color:$color1;}
    ul{list-style-type:disc;margin-bottom:20px;padding-left:15px;}
    li{margin-bottom:5px;}
    li a{color:$color1;font-weight:500;}
    p{font-weight:500;}

    @media (min-width:768px){.left{text-align:right;}}

    @include bp(tw){padding-bottom:40px;
      .r2 .left{margin-left:0;}
      .r2 .right{margin-right:-2%;}
    }

    @include bp(nn){padding:40px 0 20px;
      .med3Def{max-width:740px;}
      .r1{margin-bottom:30px;
        .left{margin-top:2%;padding-right:30px;}
        .right{width:70%;}
      }
      .r2{
        .left{padding-right:30px;margin-top:0;}
        .right{margin-right:0;}
        p br{display:none;}
      }
      h3{font-size:29px;}
    }

    @include bp(og){padding:20px 0 10px;
      .med3Def{max-width:600px;}
      .r1,.r2{
        .left,.right{@include sec-default();}
      }
      .r1{margin-bottom:0px;
        .left{margin-top:0;padding-right:0;}
        h3{text-align:center;}
      }
      .r2{text-align:center;
        .left{padding-right:0;}
      }
      h3{font-size:21px;margin-bottom:5px;}
      h3 br{display:none;}
      p,li{font-size:17px;}
    }

    @include bp(fi){
      h3{font-size:19px;margin-bottom:0;}
      p,li{font-size:16px;}
    }
  }

  .reviewRow{padding:150px 0 40px;background-color:$yellow;text-align:center; position:relative;z-index:0;//padding:185px 0 40px;
    &::before{@include before();@include sec-bgimage('./images/shotWave2w.png');background-position:top left;}

    p:not(.name){font-size:20px;}
    p.name{margin-bottom:0;font-weight:bold;font-size:15px;text-transform:uppercase;}
    
    @media (max-width:1500px){padding-top:125px;}//padding-top:160px;}
    @include bp(tw){padding:90px 0 20px;//padding:125px 0 25px;
      .med2Def{max-width:700px;}
    }
    @include bp(nn){padding:75px 0 15px;//padding:95px 0 15px;
      .med2Def{max-width:660px;}
      p:not(.name){font-size:18px;}
      p.name{font-size:14px;}
    }
    @include bp(og){padding-top:60px;//padding-top:75px;
      .med2Def{width:90%;}
      p:not(.name){font-size:17px;}
    }
    @include bp(fi){padding-top:40px;padding-bottom:8px;}
  }
  .wave2{background-color:$color1Lt;
    @media(min-width:2300px){img{max-width:unset;width:100%;}}
  }
}