@import '../../assets/variable';
@import '../../assets/mixins';
@import '../../assets/global';


.Home{
  .titleBox{padding:70px 0;text-align:center;
    h1, h2{text-transform:uppercase;margin:0;}
    h1 svg{height:45px;font-weight:300;margin-bottom:4px;}
    h2{font-size:32px;font-weight:300;}
    .linkRow{margin-top:25px;}
    a{box-shadow:0px 10px 20px rgba($black,.1);}

    @include bp(tb){h1{letter-spacing:2px;}}
    @include bp(nn){
      h1{font-size:42px;
        svg{height:40px;}
      }
      h2{font-size:30px;}
    }
    @include bp(tn){padding:50px 0;
      h1{font-size:36px;letter-spacing:0px;
        svg{height:34px;}
      }
      h2{font-size:24px;}
      a{font-size:14px;}
    }
    @include bp(og){padding:35px 0 20px;
      h1{font-size:34px;
        svg{height:30px;}
      }
      .linkRow{margin-top:20px;}
    }
    @include bp(oc){padding-top:30px;
      h1{font-size:28px;
        svg{height:27px;}
      }
      h2{font-size:18px;text-transform:none;}
      .linkRow{margin-top:10px;}
      a{padding:7px 20px;}
    }
    @include bp(fi){padding-top:15px;
      h1{font-size:24px;@include center-block();width:90%;max-width:350px;font-family:$body;line-height:1.3em;margin-bottom:7px;
        svg{height:18px;}
      }
      h2{font-size:17px;}
    }
  }

  .hs1{padding-top:10px;position:relative;
    .stackWrap{@include sec-default();position:relative;}

    .mobCol{display:none;}
    .stackCol{width:32.3%;display:inline-block;vertical-align:top;position:relative;//overflow:hidden;
      &.si1{float:left;margin-left:.6%;}
      &.si2{margin-left:.95%;}
      &.si3{float:right;margin-right:.6%;}

      a{color:inherit;}
      p.title{font-size:25px;line-height: 1.3em;margin:0;text-transform:uppercase;font-weight:bold;}
      p.title+p{font-size:16px;margin:0;line-height:1.3em;}
      .arrow{position:absolute;right:10px;top:28%;width:30px;height:30px;border-radius:50%;text-align:center;background-color:$color2-dk;//background-color:$color1Lt;
        svg{width:16px;@include center-block();margin-top:8px;fill:$white;}
      }
      .mobImg{display:none;}
    }
    .sitem{@include sec-default();position:relative;z-index:0;
      &:hover{opacity:1;}
      &:hover img{opacity:.9;@include ease(.2s);}
    }
    .stbox{padding:7px 10px 7px 15px;z-index:1;}

    .sliderItems{margin-left:0;justify-content:center;margin-top:12px;}
    .slide{padding:0 7px;
      .box{@include sec-default();padding:30px 10px;background-color:$color1Lt;text-align:center;}
      p.title{font-weight:500;margin:0;line-height:1.4em;}
      br{display:none;}
    } 

    .slideNav{background-color:$color1b;color:$color2-dk;visibility:visible !important;padding:0;margin-top:20px;margin-left:0;margin-right:0;
      width:34px;height:34px;border-radius:50%;text-align:center;box-shadow:0px 5px 20px rgba($black,.15);
      svg{width:10px;@include center-block();}

      &.prev{left:-7px;padding-right:3px;}
      &.next{right:-7px;padding-left:2px;}
    }
    .dotNav{display:none;}

    @media (min-width:768px){
      .sitem{background-color:$black;}
      .stbox{position:absolute;width:94%;left:3%;bottom:5%;box-shadow:0px 10px 30px rgba($black,.25);background-color:$white;}
    }
    @media (min-width:1201px){.rowItem br, .XXslide br{display:none;}}
    @media (min-width:1500px){.insideXl{width:90%;}}
    
    @include bp(tw){
      .stackCol{ 
        p.title{font-size:23px;}
        .arrow{width:25px;height:25px;right:5px;
          svg{width:14px;margin-top:7px;}
        }
      }

      .slideNav{
        &.prev{left:-10px;}
        &.next{right:-10px;}
      }
    }
    @include bp(tb){.stackCol p.title+p{font-size:15px;}}
    @include bp(nn){
      .stackCol{
        p.title{font-size:20px;}
        .arrow{display:none;}
      }
      .stbox{padding:5px 5px 6px;text-align:center;}
      
      .slide p{font-size:17px;}
    }
    @media (max-width:959px){.slide br{display:block;}}

    @include bp(og){
      .insideXl{width:90%;}

      .slideNav{
        &.prev{left:-15px;}
        &.next{right:-15px;}
      }


      .stack1{max-width:600px;@include center-block();}
      .stackCol{z-index:0;//padding:7px 0;
        &.si1,&.si2,&.si3{@include sec-default();margin:7px 0;}//margin:5px 0;}
        // p.title{font-size:19px;}
        
        // &::after{@include after();height:80%;top:10%;left:0;background-color:#f8f8f8;}
        // &::before{@include abs();width:180px;height:100%;top:0;background-size:100%;background-repeat:no-repeat;z-index:1;background-position:center center;}
        &::after{@include before();background-color:#f8f8f8;opacity:.87;}
        &::before{@include before();z-index:-2;background-size:100%;background-repeat:no-repeat;background-position:center center;}

        .sitem{width:auto;max-width:300px; @include center-block();padding:12px 0;}
        img{display:none;}
        // .stbox{@include sec-default();padding-top:20px;padding-bottom:20px;}
        // &.si1,&.si3{
        //   .sitem{margin-left:220px;}
        //   .stbox{text-align:left;}
        // }
        // &.si2{
        //   .sitem{float:right;margin-right:220px;}
        //   .stbox{text-align:right;}
        // }

        &.si1::before{background-image:url('../../assets/images/beachGear2.jpg');}
        &.si2::before{background-image:url('../../assets/images/beachGear5.jpg');}
        &.si3::before{background-image:url('../../assets/images/waterSports1.jpg');}
      }
    
      .sitem:hover .stbox{opacity:.85;}
    }

    @include bp(fi){
      .stackCol{
        &.si1,&.si2,&.si3{margin:4px 0;}
        &::after{opacity:.9;}

        // &::before{width:125px;}
        // &.si1,&.si3{
        //   .sitem{margin-left:135px;}
        // }
        // &.si2 .sitem{margin-right:135px;}

        p.title{font-size:19px;}//18px;}
        // p.title+p{font-size:14px;}
      }
    }

    // @media (max-width:380px){
      // .stackCol{ 
      //   &::after{height:100%;top:0;opacity:.9;}
      //   &::before{width:85px;   width:100%;z-index:-2;}


      //   .stbox{text-align:center !important;}
      //   &.si1,&.si3,&.si2{.sitem{@include center-block();}}
      // }
    // }
  }

  .hs2{padding:80px 0 60px;text-align:center;
    p.caption, h3{text-transform:uppercase;letter-spacing:.5px;}
    p.caption{font-size:18px;margin-bottom:12px;color:$color1;
      span{font-size:30px;margin:0 5px;}
    }
    h3{font-size:37px;font-family:$fun;letter-spacing:.7px;margin:0;
      b{padding-left:5px;}
    }
    .textBox{margin-top:15px;}
    p{font-size:20px;}
    .ctabox{
      p, a{font-weight:bold;font-size:21px;text-transform:uppercase;}
      p{color:$ored;}
      a{color:$orange;}
    }

    @include bp(nn){
      h3{font-size:33px;}
      p.caption{font-size:17px;margin-bottom:6px;}
      p{font-size:17px;}
      .ctabox p, .ctabox a{font-size:18px;}
    }
    @include bp(tn){padding:50px 0 20px;//padding:50px 0 40px;
      p br{display:none;}
    }
    @include bp(md){
      h3{font-size:30px;}
      p.caption{font-size:16px;
        span{margin:0 1px;}
      }
      .textBox{margin-top:5px;}
      .ctabox p, .ctabox a{font-size:17px;}
    }
    @include bp(og){padding:40px 0 20px;}
    @include bp(oc){
      p.caption{font-size:15px;}
      h3{font-size:25px;}
    }
    @media (max-width:500px){padding-bottom:10px;
      p.caption{width:95%;max-width:260px;@include center-block();
        span{display:none;}
      }
      h3{font-size:22px;}
    }
  }


  .ctaboxFull{background-color:$orange;padding:100px 0;position:relative;z-index:0;
    &::before{@include before();background-image:url('../../assets/images/shotWave2w.png');background-position:top left;background-repeat:no-repeat;background-size:100%;}
    &::after{@include after();bottom:0;left:0;background-image:url('../../assets/images/shotWave2custW.png');background-position:bottom right;background-repeat:no-repeat;background-size:100%;}

    p{color:$white;margin-bottom:0;}
    p.title{font-size:41px;line-height:1.3em;font-weight:bold;text-transform:uppercase;letter-spacing:2px;}

    .mid{margin-top:15px;text-align:center;
      p{font-size:20px;  max-width:500px;@include center-block();}
    }
    .links{text-align:right;margin-top:40px;
      a{box-shadow:0px 10px 30px rgba($black,.15);color:$orange;}
    }


    @media (min-width:1600px){padding:120px 0;}
    @media (min-width:1800px){padding:150px 0;}
    @media (min-width:2300px){padding:170px 0;}

    @include bp(dt){.medDef{max-width:1200px;}}
    @include bp(br){padding:80px 0;
      p.title{font-size:35px;}
      .col1{padding-right:15px;}
      .mid{margin-top:10px;
        p{font-size:18px;}
      }
      .links{padding-left:15px;margin-top:25px;
        a{font-size:14px;padding-left:25px;padding-right:25px;}
      }
    }
    @include bp(tn){
      p.title{font-size:31px;}
      .mid{margin-top:5px;}
      .links{margin-top:20px;}
    }
    @include bp(og){padding:55px 0 30px;text-align:center;
      &::after{display:none;}

      .col{@include sec-default();}
      p.title{font-size:25px;
        br{display:none;}
      }
      .col1{padding-right:0;}
      .mid{margin-top:0px;
        p{font-size:17px;}
      }
      .links{text-align:center;padding-left:0;margin-top:20px;}
    }
    @include bp(fi){padding-top:40px; p.title{font-size:21px;}}
  }

  .reviewSec{padding:100px 0 120px;position:relative;z-index:0;text-align:center;
    .inside{max-width:1000px;}
    .imgHlf{width:45%;float:left;margin-left:-4%;}
    .slideHlf{width:50%;float:right;margin-right:-4%;margin-top:160px;}

    .mobImg{display:none;}
    .stars{width:125px;margin-bottom:30px;@include center-block();
      img{margin-left:-10px;}
    }
    p.name{font-size:15px;font-weight:bold;text-transform:uppercase;letter-spacing:2px;}
    
    @media (max-width:1400px){
      .imgHlf{margin-left:-2%;}
      .slideHlf{margin-right:-2%;}
    }
    @include bp(tw){
      .imgHlf{margin-left:0;}
      .slideHlf{margin-right:0;}
    }
    @include bp(tb){padding:90px 0;
      .slideHlf{margin-top:100px;}
    }
    @include bp(nn){padding:70px 0;
      .inside{width:90%;}
      .imgHlf{width:42%;}
      .slideHlf{width:55%;}

      .stars{width:100px;margin-bottom:15px;}
      p{font-size:17px;}
      p.name{margin-bottom:5px;}
    }
    @include bp(tn){.slideHlf{margin-top:70px;}}

    @include bp(og){padding:30px 0;@include sec-bgimage('./images/beachGear21.jpg');background-size:cover;
      &::before{@include before();background-color:rgba($black,.7);}
      .imgHlf{display:none;}
      .slideHlf{width:100%;margin-top:0;}
      .slideContainer{padding-top:0;}
      .stars{
        .fwImg{display:none;}
        .mobImg{display:block;}
      }
      p{color:$white;}
      .dotNav{margin-bottom:0;
        a{border-color:$white;}
      }
    }
  }
}