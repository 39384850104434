$white:#fff;
$black:#000;
$black01:rgba($black, .1);
$f8:#f8f8f8;

$color1:#5d9dd6;
$color1b:#c7deee;
$color1Lt:#eef5fa;
$color2:#476eb5;
$color2-dk:#314d7e;
$yellow:#fccb0d;
$orange:#f8a61a;
$ored:#f16027;
$yover:#FEF9E6;


$body: itc-avant-garde-gothic-pro, sans-serif;
$fun: 'Better Valentina Sans';
