@import '../../assets/global.scss';
@import '../../assets/variable.scss';
@import '../../assets/mixins.scss';

.Social{display:inline-block;
  li {display: inline-block; margin:0 5px;
    &:last-child{margin-right:0;}
  }

  // &.header li{width:17px;}
  &.footer li{width:16px;}
  @include bp(tb){&.header li{width:16px;}}
  @include bp(xs){&.header li{width:15px;}}
}