@import '../../assets/variable';
@import '../../assets/mixins';
@import '../../assets/global';


/*******************************/
/*********** HEADER ***********/
/*******************************/
.headerSec{@include sec-default();text-align:center; margin-left:0;
  .mobOnly, .ftOnly, .mobNav{display:none;}
  .topHeader,.navRow{position:relative;}
  .header{padding-left:0;}
  .topRow{@include sec-default();font-size:15px;padding:5px 0px 50px;background-color:$color1Lt;position:relative;z-index:0;
    &::before{@include before();background-image:url('../../assets/images/shotWave2custW.png');background-position:bottom right;background-size:100%;background-repeat:no-repeat;}
  
    .tbarWrap{width:97%;max-width:1700px;@include center-block();}
    .dot,.book{display:none;}
    .phone{float:left;}
    .email{float:right;}
    a{color:$color2-dk;}
  }
  .topHeader{padding:0px 0 12px;margin-top:-30px;
    &, p, a{font-size:17px;letter-spacing:.5px;line-height:1em;}
  }
  a{color:$color2;}
  .book a{color:$ored;}

  .ilAbs{position:absolute;top:82px;}
  .hdIcons{left:25px;padding-top:2px;
    // .ilHd:not(.phone){margin-top:-2px;}
    .ilh1{margin-left:5px;margin-right:5px;}
    .phone{margin-right:1px;}
    .phone span{@include rotate(45deg);}//10deg
    .phone svg{height:18px;}
    .social li{width:18px;}
  }
  .hdRight{right:30px;}
  .hdLogo{max-width:350px;@include center-block();}
  .ilHd{display:inline-block;vertical-align:top;}

  .navRow{border-top:1px solid $black01;border-bottom:1px solid $black01;padding:16px 0 5px;}//box-shadow:0 2px 2px #0000000d;}
  .navWrap{justify-content:center;}
  .navItem{display:inline-block;vertical-align:top;margin:0 20px;}
  .navItem, .navItem+a{font-size:18px;line-height:1em;padding:0;}

  .dropWrap{width:auto;max-width:350px !important;left:auto !important;top:25px !important;padding:10px 10px 10px 25px;
    text-align:left;background-color:$white;box-shadow:0px 10px 30px rgba($black,.15);// @include sec-default();    
  }
  .dropLi{@include sec-default();margin:10px 0;font-size:17px;
    a{color:$color2;padding:0;}
    &:hover a, a:hover{color:$color2;opacity:.8;}
  }
  .parentIcon{width:15px;}
  

  @media (min-width:1201px){.medDef{max-width:1200px;}}
  @media (min-width:1500px){.medDef{max-width:1500px;}}
  @media (min-width:1800px){
    .topRow{min-height:100px;
      .tbarWrap{max-width:1600px;}
    }
  }
  @media (min-width:2000px){.topRow{min-height:125px;}}

  @include bp(tx){.topRow{padding-bottom:40px;}}
  @include bp(tw){
    .topRow .tbarWrap{width:95%;}
    .topHeader{margin-top:-25px;}
  }
  @include bp(tb){.navItem, .navItem+a{font-size:17px;}}
  @include bp(tn){
    .topRow{padding-bottom:30px;
      span.dot{font-size:17px;color:$color1;margin:0 8px;}
    }
    .topHeader{margin-top:-20px;border-bottom:1px solid $black01;}

    .hdLogo{max-width:300px;}
    .hdIcons,.navRow{display:none;}    
    .hdRight{top:70px;right:0px;
      a{font-size:16px;}
    }

    .mobNav{display:block;left:0;top:65px;
      button{padding:0;}
      svg{width:30px;height:30px;fill:$color2;}
    }
  }
  @include bp(og){
    .hdLogo{max-width:280px;}
    
    .mobNav{top:60px;}
    .hdRight{top:62px;
      a{font-size:15px;}
    }
  }
  @include bp(oc){
    .hdLogo{max-width:230px;}
    .mobNav{top:45px;}
    .hdRight{display:none;}
    
    .topRow{font-size:14px;
      .tbarWrap{width:98%;}
      .phone,.email{float:none;}
      .book,.dot{display:inline-block;}
    }
  }
  @media (max-width:540px){
    .topHeader{margin-top:-5px;}
    .topRow{padding-bottom:20px;
      // .phone,.email{font-size:14px;}
      .book{width:100%;line-height:1.4em;}
      span.dot{margin:0 3px;line-height:1.1em;}
      span.two{display:none;}
    }
  }
  @media (max-width:400px){
    .topRow{padding-bottom:10px;
      .phone,.email,span.dot{display:none;}
    }
    .topHeader{margin-top:0px;}
    .mobNav svg{width:25px;height:25px;}
  }
  @include bp(xx){
    .hdLogo{max-width:200px;}
  }
}

.SideNavList{padding:20px 30px 0;width:98%;max-width:400px;
  .closeIcon{position:absolute;right:10px;top:10px;cursor:pointer;font-weight:normal;
    width:30px;height:30px;background-color:$color2-dk;color:$white;border-radius:50%;text-align:center;
    svg{width:23px;height:23px;margin-bottom:2px;}
  }

  li{@include sec-default();line-height:1em;margin-bottom:20px;
    a, button{font-weight:300;text-transform:none;font-size:17px;line-height:1em;color:$color2-dk;}
    &.withDrop{border-top:1px solid $black01;padding-top:20px;
      ul{@include sec-default();margin-top:8px;}
      li:last-child{margin-bottom:10px;}
    }
    .parentRule{font-size:15px;font-weight:bold;text-transform:uppercase;position:relative;z-index:0;pointer-events:none;text-decoration:underline;
      &::before{@include before();z-index:1;}
      span{display:none;}
    }
  }
  .dropWrap{@include sec-default();max-width:100% !important;box-shadow:none;padding:0;position:relative;}
  .dropLi a{padding:0;}
  .mobOnly{display:block;}
  .fwOnly{display:none;}

  @include bp(sm){padding-left:20px;padding-right:20px;}
}
.drawerBook{margin-top:30px;margin-bottom:15px;
  a{font-weight:bold;text-transform:uppercase;color:$ored;}
}
.drawerContact{padding-left:30px;
  hr{width:85%;}

  .dcItem{@include sec-default();margin-bottom:15px;}
  p:not(.cat){font-weight:300;font-size:17px;}
  p,a{color:$color2-dk;}
  p.cat{font-size:15px;}

  @media (max-width:350px){
    p:not(.cat){font-size:16px;}
    svg{width:18px;height:18px;}
  }
  @include bp(sm){padding-left:20px;}
  @media (max-width:315px){.dcItem.dcEmail{line-height:1.2em; a{max-width:195px;word-wrap:break-word;display:block;}}}
}
